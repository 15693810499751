import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Paperclip, Send, Check, X, ArrowDownCircle, File, FileText, Repeat, MoreVertical, CornerUpRight, ThumbsUp, ThumbsDown } from 'react-feather'
import { BounceLoader, ClipLoader } from 'react-spinners'
import moment from 'moment'
import axios from 'axios'
import _, { at } from 'lodash'
import download from 'downloadjs'
import { useOrderTicketList } from '../../../../hooks/useOrderTicketList'

import createCertificate from '../../../../constants/certificate'

import Avatar from '../../../../components/Avatar'
import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'
import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import Tooltip from '../../../../components/Tooltip'
import { FloatingMenu, Radio } from '../../../../components/DesignSystem'

import './index.scss'

function Chat({ getMessages }) {
  const dispatch = useDispatch()
  const { user: { companyName, companyId, departmentId, allowedDepartments } } = useSelector(state => state.app)
  const { chat, selectedTicket, tickets, loadingChat } = useSelector(state => state.crm)

  const orderTicketList = useOrderTicketList()
  const textareaRef = useRef(null)
  const lastMessageAnchor = useRef(null)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [sending, setSending] = useState(false)
  const [prevChatLength, setPrevChatLength] = useState(0)

  const [attachment, setAttachment] = useState('')
  const [attachmentError, setAttachmentError] = useState('')
  const [chatAttachment, setChatAttachment] = useState(null)

  const [showModalMoveToSolved, setShowModalMoveToSolved] = useState(false)
  const [showModalMoveToUnsolved, setShowModalMoveToUnsolved] = useState(false)
  const [showModalReopen, setShowModalReopen] = useState(false)

  const [loadingMove, setLoadingMove] = useState(false)
  const [reopenLoading, setReopenLoading] = useState(false)

  useEffect(() => {
    scrollDownChat()
  }, [loadingChat])

  useEffect(() => {
    if ((chat.length > prevChatLength) && (chat[chat.length - 1].userProfile === 'company')) {
      setPrevChatLength(chat.length)
      scrollDownChat()
      setSending(false)
    }
  }, [chat])

  function handleChangeMessage(evt) {
    setMessage(evt.target.value)
    setTextareaAutoHeight()
  }

  const handleChangeAttachment = (event) => {
    setAttachmentError('')
    if (megaByte(event.target.files[0].size) <= 5) {
      setAttachment(event.target.files[0])
    }
    else {
      setAttachmentError("O anexo deve ser menor que 5MB.")
    }
  }

  const handleChangeChatAttachment = (event) => {
    if (megaByte(event.target.files[0].size) <= 5) {
      setChatAttachment(event.target.files[0])
    }
    else {
      dispatch({ type: 'CHANGE_SNACKBAR', payload: "O anexo deve ser menor que 5MB." })
    }
  }

  function handleClickMoveToUnsolved() {
    if (!loadingMove) {
      updateTicketStatus("unsolved")
    }
  }

  function handleClickMoveToSolved() {
    if (attachment && !loadingMove) {
      updateTicketStatus("solved")
    }
  }

  function handleClickRemoveAttachment() {
    document.getElementById('chatAttachment').value = ""
    setChatAttachment(null)
  }

  function handleClickSend() {
    if (message.trim().length === 0) {
      if (_.isEmpty(chatAttachment)) {
        dispatch({ type: 'CHANGE_SNACKBAR', payload: "O anexo precisa ser enviado junto a uma mensagem." })
        setMessageError(true)
      }
    }
    else {
      setRespond()
    }
  }

  async function handleClickMoveTicketToDepartment(department) {
    const ticketChange = await changeTicketDepartment(selectedTicket.protocol, department.key)
    if (ticketChange) {
      const payload = {
        action: 'MOVE_TICKET_TO_DEPARTMENT',
        data: {
          method: 'POST',
          path: "/tickets/change-ticket-department",
          body: {
            protocol: selectedTicket.protocol,
            departmentId: departmentId
          }
        }
      }
      dispatch({ type: 'CHANGE_CRM_UNDO', payload })
      dispatch({ type: 'CHANGE_SNACKBAR', payload: `Ticket movido para setor ${department.value}.` })
    }
  }

  function handleDropAttachment(evt) {
    evt.preventDefault()
    handleChangeChatAttachment(evt)
  }

  const megaByte = (size) => {
    return Number((size / 1000000).toFixed(1))
  }

  const kiloByte = (size) => {
    return Number((size / 1000).toFixed(1))
  }

  const base64 = (bytes, type) => {
    const byteCharacters = atob(bytes);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: type })
    return file
  }

  function downloadAttachment(message, attachment) {
    dispatch({ type: 'CHANGE_LOADING_MESSAGE', payload: { messageId: message._id, attachmentId: attachment._id, loading: true } })
    axios.get(`/messages/download/${attachment._id}`)
      .then(response => {
        download(base64(response.data, attachment.type), returnAttachmentName(attachment.key), attachment.type)
        dispatch({ type: 'CHANGE_LOADING_MESSAGE', payload: { messageId: message._id, attachmentId: attachment._id, loading: false } })
      }).catch(e => {
        console.log(e)
      })
  }

  async function updateTicketStatus(status) {
    setLoadingMove(true)

    let formData = new FormData()
    formData.append('status', status)
    formData.append('protocol', selectedTicket.protocol)

    switch (status) {
      case "solved":
        formData.append('attachment', attachment, `Certificado ${selectedTicket.protocol}.pdf`)
        break;
      case "unsolved":
        const file = await createCertificate(selectedTicket.protocol, selectedTicket.status, chat[0].createdAt, selectedTicket.lastMessageAnchorDate, selectedTicket.contactName, selectedTicket.contactDocument, companyName, companyId, chat[0], chat)
        formData.append('attachment', file, `Certificado ${selectedTicket.protocol}.pdf`)
        break;
      default:
        break;
    }

    axios.put('/tickets/update-status', formData)
      .then(response => {
        dispatch({ type: 'CHANGE_CRM_SELECTED_TICKET', payload: { ...selectedTicket, status: status } })
        dispatch({ type: 'CHANGE_SNACKBAR', payload: "Ticket atualizado com sucesso" })
        dispatch({ type: 'ADD_CRM_CHAT', payload: [response.data] })
        dispatch({ type: 'REMOVE_CRM_TICKET_FROM_LIST' })
        setAttachment('')
        setShowModalMoveToSolved(false)
        setShowModalMoveToUnsolved(false)
        setTimeout(() => setLoadingMove(false), 500)
      })
      .catch(error => {
        console.log(error)
        setLoadingMove(false)
        if (error.response?.status === 403) {
          dispatch({ type: 'CHANGE_SNACKBAR', payload: "Esse ticket não está Pendente" })
          dispatch({ type: 'CHANGE_CRM_SELECTED_TICKET', payload: { ...selectedTicket, status: error.response.data.status } })
          setLoadingMove(false)
          setShowModalMoveToSolved(false)
          setShowModalMoveToUnsolved(false)
        } else {
          setAttachmentError('Erro ao enviar anexo. Tente novamente')
        }
      })
  }

  function setRespond() {
    setSending(true)
    let formData = new FormData()
    formData.append('attachment', chatAttachment)
    formData.append('message', message)
    formData.append('protocol', selectedTicket.protocol)

    axios.post('/messages/respond', formData)
      .then(response => {
        let ticketsCopy = tickets
        ticketsCopy = ticketsCopy.map(ticket => {
          if (ticket._id === selectedTicket._id) {
            return { ...ticket, lastMessageAnchor: response.data.text, lastMessageAnchorDate: response.data.createdAt }
          } else {
            return ticket
          }
        })
        dispatch({ type: 'CHANGE_TICKETS_PREVENT_REQUEST', payload: true })
        dispatch({ type: 'CHANGE_CRM_TICKETS', payload: ticketsCopy })
        // dispatch({ type: 'ADD_CRM_CHAT', payload: response.data })
        setTimeout(() => {
          dispatch({ type: 'CHANGE_TICKETS_PREVENT_REQUEST', payload: false })
        }, 500)
        setMessage('')
        setSending(false)
        setTextareaAutoHeight()
        handleClickRemoveAttachment()
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 403) {
          dispatch({ type: 'CHANGE_SNACKBAR', payload: "Esse ticket não está Pendente" })
          dispatch({ type: 'CHANGE_CRM_SELECTED_TICKET', payload: { ...selectedTicket, status: error.response.data.status } })
          setSending(false)
          setMessage('')
        }
      })
  }

  function setTodayOrYesterday(date) {
    let titleDate = new Date(date)
    let todayDate = new Date()
    let yesterdayDate = new Date()
    yesterdayDate.setDate(todayDate.getDate() - 1)

    if (titleDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
      return <span>Hoje</span>
    }

    if (titleDate.setHours(0, 0, 0, 0) === yesterdayDate.setHours(0, 0, 0, 0)) {
      return <span>Ontem</span>
    }

    return <span>{moment(date).local().format('DD/MM/YYYY')}</span>
  }

  function setTextareaAutoHeight() {
    textareaRef.current.style.height = '0px'
    textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 200)}px`
  }

  function formatChat() {
    let dates = []
    let messagesFromDate = []

    chat.map(item => {
      let date = `${moment(item.createdAt).local().format('DD/MM/YYYY')}`
      if (!dates.find(e => e === date)) {
        dates.push(date)
      }
      if (!messagesFromDate[date]) {
        messagesFromDate[date] = []
      }
      return messagesFromDate[date].push(item)
    })

    let result = []
    dates.map(item => {
      let temp = {
        title: item,
        data: messagesFromDate[item]
      }
      return result.push(temp)
    })
    return result
  }

  function scrollDownChat() {
    (!_.isEmpty(selectedTicket) && !_.isEmpty(chat)) && setTimeout(() => lastMessageAnchor.current.scrollIntoView({ behavior: "smooth" }), 500)
  }

  async function changeTicketDepartment(protocol, departmentId) {
    let ticketsList = orderTicketList(tickets)
    let nextProtocol
    if (ticketsList[0].protocol === selectedTicket.protocol) {
      nextProtocol = ticketsList[1]
    }
    else {
      nextProtocol = ticketsList[0]
    }

    try {
      const response = await axios.post('/tickets/change-ticket-department', { protocol, departmentId })
      dispatch({ type: 'REMOVE_CRM_TICKET_FROM_LIST' })
      dispatch({ type: 'CHANGE_CRM_SELECTED_TICKET', payload: nextProtocol })
      if (nextProtocol) {
        getMessages(nextProtocol.protocol)
      }
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  function returnDepartmentsSubMenu() {
    if (!_.isEmpty(allowedDepartments)) {
      if (allowedDepartments.length >= 1) {
        return ([
          { type: "divider" },
          {
            type: "subOptions",
            label: "Mudar para outro setor",
            options: allowedDepartments.filter(item => item.key !== departmentId && item).map(item => ({ label: item.value, action: () => handleClickMoveTicketToDepartment(item) }))
          }
        ])
      } else return ([])
    } else return ([])
  }

  function returnChatHeaderButtons(selectedTicket) {
    let days = dateDiff(chat[chat.length - 1]?.createdAt)
    if (selectedTicket.status === 'open' || selectedTicket.status === 'waiting') {
      return (
        <>
          {(selectedTicket.status === 'waiting' && selectedTicket.contactType === 'Whatsapp' && days >= 7) ?
            <FloatingMenu
              position="bottom"
              align="left"
              options={[
                { label: "Reabrir conversa", action: () => setShowModalReopen(true) },
                { type: "divider" },
                { label: "Marcar como Resolvido", action: () => setShowModalMoveToSolved(true) },
                { label: "Marcar como Não Resolvido", action: () => setShowModalMoveToUnsolved(true) },
                ...returnDepartmentsSubMenu()
              ]}
              classNameButtonContainer="chat__header__actions__button"
            >
              <MoreVertical />
            </FloatingMenu>
            :
            <FloatingMenu
              position="bottom"
              align="left"
              options={[
                { label: "Marcar como Resolvido", action: () => setShowModalMoveToSolved(true) },
                { label: "Marcar como Não Resolvido", action: () => setShowModalMoveToUnsolved(true) },
                ...returnDepartmentsSubMenu()
              ]}
              classNameButtonContainer="chat__header__actions__button"
            >
              <MoreVertical />
            </FloatingMenu>
          }
        </>
      )

    }
    if (selectedTicket.status === 'solved') {
      return (
        <>
          <div className="chat__header__actions__button chat__header__actions__button--solved"><ThumbsUp /></div>
        </>)
    }
    if (selectedTicket.status === 'unsolved') {
      return (
        <>
          <div className="chat__header__actions__button chat__header__actions__button--unsolved"><ThumbsDown /></div>
        </>
      )

    }
  }

  function dateDiff(messageDate) {
    let today = new Date()
    let otherDate = new Date(messageDate)
    let timeInMilisec = today.getTime() - otherDate.getTime();
    let daysBetweenDates = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));

    return daysBetweenDates
  }

  function returnMessageStyle(profile, attachment, index) {

    const setProfile = (profile, attachment) => {
      switch (profile) {
        case 'company':
          return 'chat__messages__message chat__messages__message chat__messages__message--company'
        case 'client':
          return ' chat__messages__message chat__messages__message chat__messages__message--client'
        case 'system':
          if (selectedTicket.status === 'solved') {
            return ' chat__messages__message chat__messages__message chat__messages__message--system__solved'
          }
          if (selectedTicket.status === 'unsolved') {
            return ' chat__messages__message chat__messages__message chat__messages__message--system__unsolved'
          }
        default:
          break;
      }
    }

    if (index === 0) {
      return setProfile(profile, attachment) + '--unique'
    }

    if (profile === 'system') {
      return setProfile(profile, attachment)
    }

    if (index === 1) {
      let actualMessageDate = moment(chat[index].createdAt).local().format('MMMM Do YYYY, HH:mm')
      let nextMessageDate = moment(chat[index + 1]?.createdAt).local().format('MMMM Do YYYY, HH:mm')

      if (chat[index + 1]?.userProfile === profile) {
        if (actualMessageDate === nextMessageDate) {
          return setProfile(profile, attachment) + '--first'
        }
        else {
          return setProfile(profile, attachment) + '--unique'
        }
      }
      else {
        return setProfile(profile, attachment) + '--unique'
      }
    }

    if (index === chat.length - 1) {
      let previousMessageDate = moment(chat[index - 1]?.createdAt).local().format('MMMM Do YYYY, HH:mm')
      let actualMessageDate = moment(chat[index].createdAt).local().format('MMMM Do YYYY, HH:mm')
      if (chat[index - 1]?.userProfile === profile) {
        if (previousMessageDate === actualMessageDate) {
          return setProfile(profile, attachment) + '--last'
        }
        else {
          return setProfile(profile, attachment) + '--unique'
        }
      }
      else {
        return setProfile(profile, attachment) + '--unique'
      }
    }

    if ((chat.length > 1) && (chat[index - 1]?.userProfile === profile) && (chat[index + 1]?.userProfile === profile)) {
      let previousMessageDate = moment(chat[index - 1].createdAt).local().format('MMMM Do YYYY, HH:mm')
      let actualMessageDate = moment(chat[index].createdAt).local().format('MMMM Do YYYY, HH:mm')
      let nextMessageDate = moment(chat[index + 1].createdAt).local().format('MMMM Do YYYY, HH:mm')

      if (previousMessageDate === actualMessageDate && actualMessageDate !== nextMessageDate) {
        return setProfile(profile, attachment) + '--last'
      }

      if (previousMessageDate !== actualMessageDate && actualMessageDate !== nextMessageDate) {
        return setProfile(profile, attachment) + '--unique'
      }

      if (previousMessageDate === actualMessageDate && actualMessageDate === nextMessageDate) {
        return setProfile(profile, attachment)
      }
      else {
        return setProfile(profile, attachment) + '--first'
      }
    }

    if (chat[index - 1]?.userProfile !== profile && chat[index + 1]?.userProfile !== profile) {
      return setProfile(profile, attachment) + '--unique'
    }

    if (chat[index - 1]?.userProfile !== profile && index > 1) {
      let actualMessageDate = moment(chat[index].createdAt).local().format('MMMM Do YYYY, HH:mm')
      let nextMessageDate = moment(chat[index + 1].createdAt).local().format('MMMM Do YYYY, HH:mm')

      if (actualMessageDate !== nextMessageDate) {
        return setProfile(profile, attachment) + '--unique'
      }
      if (chat[index + 1]?.userProfile === profile) {
        return setProfile(profile, attachment) + '--first'
      }
    }

    if (chat[index - 1]?.userProfile === profile) {
      if (chat[index + 1]?.userProfile !== profile) {
        return setProfile(profile, attachment) + '--last'
      }
    }

    // console.log({chat: chat[index].text, index, className})

    // switch (profile) {
    //   case 'company':
    //     return 'chat__messages__message chat__messages__message chat__messages__message--company'
    //   case 'client':
    //     return ' chat__messages__message chat__messages__message chat__messages__message--client'
    //   case 'system':
    //     if (attachment) {
    //       return ' chat__messages__message chat__messages__message chat__messages__message--system__solved'
    //     }
    //     else {
    //       return ' chat__messages__message chat__messages__message chat__messages__message--system__unsolved'
    //     }
    //   default:
    //     break;
    // }
  }

  function returnDownloadAttachmentMessage(messageData) {

    if (['solved', 'unsolved'].includes(selectedTicket.status) && messageData.userProfile === 'system') {
      return (
        <div>
          {messageData.attachment.map((item, i) => {
            return (
              <div key={i} className="chat__messages__message__action" onClick={() => item.key && downloadAttachment(messageData, item)}>
                {item.loading ? <BounceLoader color='white' size={20} /> :
                  <>
                    <File className="chat__messages__message__action__icon" />
                    <ArrowDownCircle className="chat__messages__message__action__icon__hover" />
                  </>}
                {returnAttachmentName(item.key)}
              </div>)
          })}
        </div>)
    }

    if (!_.isEmpty(messageData.attachment)) {
      return (
        <div>
          {messageData.attachment.map((item, i) => {
            return (
              <div key={i} className={`chat__messages__message__action chat__messages__message__action-${messageData.userProfile}`} onClick={() => item.key && downloadAttachment(messageData, item)}>
                {item.loading ? <BounceLoader size={20} /> : <File />}
                {returnAttachmentName(item.key)}
              </div>)
          })}
        </div>)
    }
  }

  function returnAttachmentName(string = '') {
    if (_.isEmpty(string)) return null
    return string.slice(string.indexOf('-') + 1)
  }

  function returnTextareaPlaceholder() {
    switch (selectedTicket.status) {
      case "open":
        return "Escreva aqui sua mensagem"
      case "solved":
      case "unsolved":
        return "Você não pode enviar mensagens, caso encerrado."
      case "waiting":
        return "Você ainda não pode enviar mensagens."
      default:
        break;
    }
  }

  function returnMessageTime(element, index, chat) {
    if (!_.isEmpty(chat) && chat.length > 1) {
      let actualMessageDate = moment(chat[index]?.createdAt).local().format('MMMM Do YYYY, HH:mm')
      let nextMessageDate = moment(chat[index + 1]?.createdAt).local().format('MMMM Do YYYY, HH:mm')

      if (actualMessageDate !== nextMessageDate || chat.length === index + 1) {
        return (
          <div className={element.userProfile === 'company' ? "chat__messages__created-at" : "chat__messages__created-at chat__messages__created-at--client"}>
            <span>{moment(element.createdAt).utc().local().format("HH:mm")}</span>
            {element.userProfile === 'company' &&
              <Tooltip text={`${element.userName} ${element.department}`} position="bottom" align="right">
                <Avatar size='xsmall' name={element.userName} />
              </Tooltip>
            }
          </div>
        )
      }
    }
    return
  }

  async function generateSolvedCertificate() {
    let file = await createCertificate(selectedTicket.protocol, 'solved', chat[0].createdAt, selectedTicket.lastMessageAnchorDate, selectedTicket.contactName, selectedTicket.contactDocument, companyName, companyId, chat[0], chat)
    file.name = `Certificado ${selectedTicket.protocol}`
    setAttachment(file)
  }

  async function reopenTicket() {
    setReopenLoading(true)
    axios.put('/tickets/reopen', { protocol: selectedTicket.protocol })
      .then(response => {
        setShowModalReopen(false)
        setReopenLoading(false)
      }).catch(e => {
        console.log(e)
        setReopenLoading(false)
      })
  }

  return (
    <>
      <section className="chat">
        {!_.isEmpty(selectedTicket) &&
          <>
            <header>
              <div className="chat__header__title">
                <div className="chat__header__title__avatar">
                  {selectedTicket.contactName && <Avatar name={selectedTicket.contactName} size="medium" />}
                </div>
                {selectedTicket.contactName && <div className="chat__header__title__text"><Tooltip text={selectedTicket.contactName} position="bottom"><h1>{selectedTicket.contactName}</h1></Tooltip></div>}
                {selectedTicket.protocol && <div className="chat__header__title__text"><Tooltip text={selectedTicket.protocol} position="bottom"><p>{selectedTicket.protocol}</p></Tooltip></div>}
              </div>
              <div className="chat__header__actions">
                {returnChatHeaderButtons(selectedTicket)}
              </div>
            </header>

            <main>
              <div className="chat__messages">
                <div className="chat__messages__container">
                  {loadingChat && <Spinner containerHeigth="80vh" type="green" />}
                  {!loadingChat && formatChat().map((el, index) => {
                    return (
                      <div key={index} >

                        {/* date separator */}
                        <div className="chat__messages__separator">
                          {setTodayOrYesterday(el.data[0].createdAt)}
                        </div>

                        {el.data.map((e, i) => (
                          <div key={e._id} className="chat__messages__section" >

                            <div className={returnMessageStyle(e.userProfile, e.attachment, e.sequential)}>
                              {(e.sequential === 0) ?
                                <div dangerouslySetInnerHTML={{ __html: e.text }} />
                                :
                                e.text.length > 0 && <div style={!_.isEmpty(e.attachment) ? { marginBottom: '10px' } : {}} dangerouslySetInnerHTML={{ __html: e.text }} />
                              }
                              {returnDownloadAttachmentMessage(e)}

                              {returnMessageTime(e, e.sequential, chat)}
                            </div>

                            {/* divisor between form and chat messages */}
                            {(e.sequential === 0) &&
                              <div className="chat__messages__divisor">
                                <div className="chat__messages__divisor__line"></div><span>Chat</span><div className="chat__messages__divisor__line"></div>
                              </div>
                            }

                          </div>
                        ))}
                      </div>
                    )
                  }
                  )}
                  <div ref={lastMessageAnchor} />
                </div>
              </div>
            </main>


            <footer className={selectedTicket.status === 'open' ? "chat__actions" : "chat__actions chat__actions--disable"} onDrop={handleDropAttachment}>
              <div className={chatAttachment ? "chat__attachments chat__attachments--visible" : "chat__attachments"}>
                {chatAttachment &&
                  <div className="chat__attachments__file">
                    {/* <File /> */}
                    <span>{chatAttachment.name}</span>
                    <X size={16} onClick={handleClickRemoveAttachment} />
                  </div>
                }
              </div>

              <div className="chat__actions__button" onClick={() => document.getElementById('chatAttachment').click()}>
                <input id="chatAttachment" type="file" hidden onChange={handleChangeChatAttachment} />
                <Paperclip />
              </div>

              <div className={messageError ? "chat__actions__message chat__actions__message--error" : "chat__actions__message"}>
                <textarea ref={textareaRef} placeholder={returnTextareaPlaceholder()} value={message} onChange={handleChangeMessage} onClick={() => setMessageError(false)} />
              </div>

              <div className="chat__actions__button chat__actions__button--green" onClick={!sending && handleClickSend}>
                {sending ? <ClipLoader color="white" size={24} /> : <Send />}
              </div>
            </footer>
          </>
        }
      </section>

      <Modal
        show={showModalMoveToUnsolved}
        title="Mover para Não Resolvidos?"
        subtitle="Esta ação não pode ser desfeita"
        close={() => setShowModalMoveToUnsolved(false)}
        modalWidth={400}
        renderFooter={
          <div>
            <Button width='48%' height='50px' type={3} onClick={() => setShowModalMoveToUnsolved(false)}>Não</Button>
            <Button width='48%' height='50px' type={4} loading={loadingMove} onClick={handleClickMoveToUnsolved}>Sim</Button>
          </div>
        }
      />

      <Modal
        show={showModalMoveToSolved}
        title="Mover para Resolvidos?"
        subtitle="Esta ação não pode ser desfeita"
        close={() => setShowModalMoveToSolved(false)}
        modalWidth={400}
        children={
          <div>
            <input id="fileButton" type="file" hidden onChange={handleChangeAttachment} />
            <div className="document__holder">
              {
                attachment ?
                  <div className="document__upload">
                    {/* <div className="document__upload__icon">
                      <Paperclip />
                    </div> */}
                    <span>{attachment.name}</span>
                    <small>{`(${kiloByte(attachment.size)}Kb)`}</small>
                    <X size={16} onClick={() => { document.getElementById('fileButton').value = ""; setAttachment(null) }} />
                  </div>
                  :
                  <>
                    <div className="document__upload__button" onClick={() => document.getElementById('fileButton').click()}><Paperclip />Anexar certificado</div>
                    <div className="document__upload__button" style={{ marginTop: '20px' }} onClick={() => generateSolvedCertificate()}><FileText />Gerar certificado automático</div>
                  </>
              }
            </div>
            <div className="modal__message">{attachmentError}</div>
          </div>
        }
        renderFooter={
          <div>
            <Button width='48%' height='50px' type={3} onClick={() => setShowModalMoveToSolved(false)}>Voltar</Button>
            <Button width='48%' height='50px' type={attachment ? 2 : 20} loading={loadingMove} onClick={handleClickMoveToSolved}>Confirmar</Button>
          </div>
        }
      />

      <Modal
        show={showModalReopen}
        title="Reativar ticket?"
        subtitle="Esta ação não pode ser desfeita"
        close={() => setShowModalReopen(false)}
        modalWidth={400}
        renderFooter={
          <div>
            <Button width='48%' height='50px' type={3} onClick={() => setShowModalReopen(false)}>Não</Button>
            <Button width='48%' height='50px' type={4} loading={reopenLoading} onClick={() => reopenTicket()}>Sim</Button>
          </div>
        }
      />
    </>
  )
}

export default Chat
