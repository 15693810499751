import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import Input from '../../components/Input';
import Button from '../../components/Button'

import { ReactComponent as Logo } from '../../assets/logo-name.svg'
import './index.scss'


function Login() {
  let history = useHistory();
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [loginMessage, setLoginMessage] = useState('')

  const [showForgetPassword, setShowForgetPassword] = useState(false)

  useEffect(() => { setLoginError('') }, [showForgetPassword])

  function handleClickNewPassword() {
    setLoading(true)
    axios.post('/users/email-password', { email: email })
      .then(response => {
        console.log(response)
        setLoading(false)
        clearUserCredentials()
        setLoginMessage('Enviamos um email para você redefinir sua senha.')
        setLoginError('')
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        clearUserCredentials()
        setLoginMessage('')
        setLoginError('Redifinição incompleta. Tente novamente mais tarde.')
      })
  }

  function clearUserCredentials() {
    setEmail('')
    setPassword('')
  }

  function getGreeting() {
    const hour = new Date().getHours()

    if (hour > 5 && hour < 12) return "Bom dia";
    else if (hour >= 12 && hour < 18) return "Boa tarde";
    else return "Boa noite";
  }

  function login() {
    setLoading(true)
    axios.post('/users/login', { email, password })
      .then(response => {
        dispatch({ type: 'CHANGE_USER', payload: response.data })
        axios.defaults.headers.common['authentication'] = response.data.token
        localStorage.setItem('user', JSON.stringify(response.data));
        setLoading(false)
        history.push('/crm')
      }).catch(error => {
        console.log(error)
        setLoading(false)
        if (!error.response || (error.response.status !== 401)) {
          setLoginMessage('')
          setLoginError('Login incompleto. Tente novamente mais tarde.')
        } else {
          setLoginMessage('')
          setLoginError('Usuário e/ou senha inválidos.<br/> Tente novamente ou clique em "Esqueceu a senha?" para redefini-la.')
        }
      })
  }

  function changeToForgetPassword(boll) {
    setLoginMessage('')
    setLoginMessage('')
    setShowForgetPassword(boll)
  }

  return (
    <div className='login'>
      <section>
        <header>
          <div className='login__logo'><Logo /></div>
        </header>
        {!showForgetPassword &&
          <main className='login__form'>
            <p>Fazer login</p>
            <div className='login__form__greetings'>{getGreeting()}. Ficamos felizes em tê-lo conosco.</div>
            <Input placeholder={'Email'} inputType='email' value={email} onChange={setEmail} onPressEnter={() => login()} />
            <Input placeholder={'Senha'} password={true} value={password} onChange={setPassword} onPressEnter={() => login()} />
            <div className={loginMessage ? "login__form__message login__form__message--visible" : "login__form__message"} dangerouslySetInnerHTML={{ __html: loginMessage }} />
            <div className={loginError ? "login__form__error login__form__error--visible" : "login__form__error"} dangerouslySetInnerHTML={{ __html: loginError }} />
            <Button height='50px' loading={loading} onClick={() => login(email, password)} submit={true}>Continuar</Button>
            <div className='login__form__forget__password' onClick={() => changeToForgetPassword(true)}>Esqueceu sua senha?</div>
          </main>
        }
        {showForgetPassword &&
          <main className='login__form'>
            <p>Esqueci minha senha</p>
            <div className='login__form__greetings'>Informe seu Email e aguarde nosso contato</div>
            <Input placeholder={'Email'} inputType='email' value={email} onChange={setEmail} onPressEnter={() => login()} />
            <div className={loginMessage ? "login__form__message login__form__message--visible" : "login__form__message"} dangerouslySetInnerHTML={{ __html: loginMessage }} />
            <div className={loginError ? "login__form__message login__form__message--visible" : "login__form__message"} dangerouslySetInnerHTML={{ __html: loginError }} />
            <Button height='50px' loading={loading} onClick={handleClickNewPassword} submit={true}>Redefinir Senha</Button>
            <div className='login__form__forget__password' onClick={() => changeToForgetPassword(false)}>Voltar para o login</div>
          </main>
        }
        <footer>
          {/* Ao fazer login você concorda com a nossa <a href='#' target='_blank'>Política de privacidade</a>. */}
        </footer>
      </section>
      <div className='login__image'>
        {/* NASDAQ 2025
        <span role="img" aria-label="rocket">🚀</span> */}
      </div>
    </div>
  );
}

export default Login;