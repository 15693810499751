import React from 'react'
import './index.scss'

function Checkbox({ value = false, disabled = false, onChange = checked => { }, ...props }) {
  const Check = () => <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.125 6.5125L0 3.3875L0.88125 2.50625L3.125 4.74375L7.86875 0L8.75 0.8875L3.125 6.5125Z" fill="#FFF" />
  </svg>

  return (
    <div className={disabled ? "checkbox checkbox--disabled" : "checkbox"} onClick={() => onChange(!value)} {...props}>
      <input type="checkbox" checked={value} />
      <span className={value ? "checkbox__checkmark checkbox__checkmark--checked" : "checkbox__checkmark"}>
        {value && <Check />}
      </span>
    </div>

  )
}

export default Checkbox
