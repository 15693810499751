import React from 'react'
import './index.scss'

//TODO: permitir personalizar cor do texto e do fundo

/**
 * @param {*} align String "left" ou "right" ou "center" | default "center"
 * @param {*} delay Tempo em segundos | default 0.5
 * @param {*} horizontalPosition Quando passada remove o delay e o transition e aplica uma posição específica | default `null`
 * @param {*} position String "top" ou "bottom" | default "top"
 * @param {*} text String vazia tooltip disable
 */
function Tooltip({ text = "", position = "top", align = "center", delay = 0.5, horizontalPosition, children }) {
  function setPositon() {
    if (position === "top") {
      return "tooltip--top"
    }
    return "tooltip--bottom"
  }
  function setAlign() {
    if (align === "left") {
      return "tooltip--align--left"
    } else if (align === "center") {
      return "tooltip--align--center"
    } else {
      return "tooltip--align--right"
    }
  }
  function hasHorizontalPosition() {
    if (horizontalPosition) {
      return { transitionDelay: 0, transition: 'none', left: `${horizontalPosition}%` }
    }
    return { transitionDelay: `${delay}s` }
  }

  return (
    <div className={`tooltip ${setPositon()} ${setAlign()}`}>
      {text && <div className="tooltip__text" style={hasHorizontalPosition()}>{text}</div>}
      {children}
    </div>
  )
}

export default Tooltip
