import OutsideClickHandler from 'react-outside-click-handler'
import { X } from 'react-feather'

import './index.scss'

function Modal({ show = false, close, showCloseButton = false, title, subtitle, titleWidth = 0, modalWidth = 0, children, renderFooter, backdropColor }) {

  const handleCloseModal = () => close && close()

  return (
    <div className={`modal ${show && 'modal--visible'}`}>
      <OutsideClickHandler onOutsideClick={handleCloseModal}>
        <section className={`modal__container ${show && "modal__container--visible"}`} style={{ minWidth: modalWidth > 0 ? modalWidth : 'auto', maxWidth: modalWidth > 0 ? modalWidth : 'auto' }}>
          {showCloseButton && <X className="modal__container__close" size={20} onClick={handleCloseModal} />}
          {title &&
            <header className="modal__container__header">
              <div className="modal__container__title" style={{ width: titleWidth > 0 ? titleWidth : 'auto' }}>
                <h1>{title}</h1>
                <small>{subtitle}</small>
              </div>
            </header>
          }

          {children &&
            <main className="modal__container__content">
              {children}
            </main>
          }

          {renderFooter &&
            <footer className="modal__container__footer">
              {renderFooter}
            </footer>
          }
        </section>
      </OutsideClickHandler>
    </div>
  )
}

export default Modal
