import React, { useState, useRef, useEffect } from "react";
import './index.scss'

function Tooltip({ children, position = "top", delay = 1 }) {
  const tooltipRef = useRef(null);
  const [mouseOver, setMouseOver] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState([null, null]);

  useEffect(() => {
    setPosition();
    setTooltipText(children?.props?.children || children);
  }, []);

  function handleMouseEnter() {
    setMouseOver(true);
    setPosition();
  }
  function handleMouseLeave() {
    setMouseOver(false);
  }

  function setPosition() {
    const {
      top,
      right,
      bottom,
      left
    } = tooltipRef.current.getBoundingClientRect();
    setTooltipPosition([(left + right) / 2, (top + bottom) / 2]);
  }

  return (
    <span
      ref={tooltipRef}
      className="tooltip__container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <span
        className={
          mouseOver
            ? `tooltip__card tooltip__card--open tooltip__card--${position}`
            : "tooltip__card tooltip__card--close"
        }
        style={{
          left: tooltipPosition[0],
          top: tooltipPosition[1],
          transitionDelay: mouseOver ? `${delay}s` : ".5s"
        }}
      >
        {tooltipText}
      </span>
    </span>
  );
}

export default Tooltip