import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Sliders, ArrowLeft } from 'react-feather'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import axios from 'axios'
import _ from 'lodash'

import Menu from '../../components/Menu'
import LinearProgress from '../../components/LinearProgress'
import Accordion from '../../components/Accordion'
import Percentage from '../../components/Percentage'
import Spinner from '../../components/Spinner'
import Chip from '../../components/DesignSystem/Chip'
import Select from './components/Select';
import Tooltip from '../../components/DesignSystem/Tooltip'

import './index.scss'
import { ptBR } from 'date-fns/locale';

function Dashboard() {
  const { user: { token } } = useSelector(state => state.app)
  const [questions, setQuestions] = useState([])
  const [options, setOptions] = useState([])
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const [loadingOptions, setLoadingOptions] = useState(true)
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      dateChip: 30,
      count: 0
    }
  ])
  const [statusFilter, setStatusFilter] = useState({
    waiting: false,
    pending: false,
    expired: false,
    refused: false,
    solved: false,
    unsolved: false
  })
  const [dateChip, setDateChip] = useState(30)
  const [questionFilter, setQuestionFilter] = useState()
  const [treeFilter, setTreeFilter] = useState([])
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    status: {
      waiting: false,
      pending: false,
      expired: false,
      refused: false,
      solved: false,
      unsolved: false
    }
  })
  const [nextQuestion, setNextQuestion] = useState('')

  useEffect(() => {
    if (token) {
      getQuestions()
    }
  }, [token])

  useEffect(() => {
    if (dateChip > 0) {
      let today = new Date()

      let lastDate = new Date()
      lastDate.setDate(today.getDate() - dateChip)

      let newArr = [...dateRange]
      newArr[0] = {
        startDate: lastDate,
        endDate: today,
        key: 'selection',
        dateChip,
        count: 0
      }
      setDateRange(newArr)
      setFilters(prevState => ({
        ...prevState,
        startDate: lastDate,
        endDate: today
      }));
    }
  }, [dateChip])

  useEffect(() => {
    if (questionFilter) {
      let newTree = questionFilter
      newTree.selectedValue = ''
      setTreeFilter([newTree])
      setNextQuestion('')
    }
  }, [questionFilter])

  useEffect(() => {
    if (treeFilter[treeFilter.length - 1]?.selectedValue) {
      let { options, selectedValue } = treeFilter[treeFilter.length - 1]
      options.map(item => {
        if (item.text === selectedValue) {
          setNextQuestion(item.nextQuestionId)
        }
      })
    }
  }, [treeFilter])

  useEffect(() => {
    if (!_.isEmpty(questions)) {
      let questionId = treeFilter[treeFilter.length - 1]?._id
      let id = null

      if ((questionId && treeFilter.length > 1) || nextQuestion) {
        id = nextQuestion === '' ? questionId : nextQuestion
      }
      else {
        if (questionFilter) {
          id = questionFilter._id
        } else {
          id = questions[0]._id
        }
      }

      setLoadingOptions(true)
      let tree = treeFilter
      let source = axios.CancelToken.source()
      axios.post(`/dashboard/get-options/${id}`,
        { filters, tree },
        { cancelToken: source.token })
        .then(response => {
          setOptions(_.orderBy(response.data.totalOptions, ["percentage", "text"], "desc"))
          setLoadingOptions(false)
          if (nextQuestion !== questionId && nextQuestion !== '') {
            let newTreeFilter = [...treeFilter]
            newTreeFilter.push(response.data.foundQuestion)
            setTreeFilter(newTreeFilter)
          }
        })
        .catch(error => console.log(error))
      return () => {
        source.cancel()
      }
    }
  }, [filters, questions, nextQuestion, questionFilter])

  function getQuestions() {
    axios.get('/dashboard/get-questions')
      .then(response => {
        setQuestions(response.data.questions)
        setQuestionFilter(response.data.questions[0])
        setTreeFilter([{ ...response.data.questions[0], selectedValue: '' }])
        setLoadingQuestions(false)
      })
      .catch(error => console.log(error))
  }

  function getDash(questionId, tree = false) {
    setLoadingOptions(true)
    let filters = {
      statusFilter,
      dateRange
    }
    let source = axios.CancelToken.source()
    axios.post(`/dashboard/get-options/${questionId}`,
      { questionId, filters },
      { cancelToken: source.token })
      .then(response => {
        setOptions(_.orderBy(response.data.totalOptions, ["percentage", "text"], "desc"))
        setLoadingOptions(false)
        if (tree) {
          let newTreeFilter = [...treeFilter]
          newTreeFilter.push(response.data.foundQuestion)
          setTreeFilter(newTreeFilter)
        }
      })
      .catch(error => console.log(error))
    return () => {
      source.cancel()
    }
  }

  function renderTotalAnswer(total) {
    total = Number(total)
    if (total === 1) {
      return "1 resposta"
    } else if (total > 1) {
      return `${total} respostas`
    } else {
      return "0 resposta"
    }
  }

  function handleStatusFilterChange(status, value) {
    setStatusFilter(prevState => ({
      ...prevState,
      [status]: value
    }));

    let newObj = { ...filters.status }
    newObj[status] = value
    setFilters(prevState => ({
      ...prevState,
      status: newObj
    }));
  }

  function handleDateChange(value) {
    let today = new Date()
    today.setHours(0, 0, 0, 0)

    let startDate = value[0].startDate
    let endDate = value[0].endDate

    let timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    let differentDays = 0

    if (endDate.getTime() === today.getTime()) {
      differentDays = Math.ceil(timeDifference / (1000 * 3600 * 24))
    }

    let newArr = [...dateRange]
    let count = newArr[0].count + 1
    newArr[0] = {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
      dateChip: differentDays,
      count
    }

    setDateRange(newArr)
    if (count % 2 === 0) {

      if (endDate.getTime() !== today.getTime() || (differentDays !== 7 && differentDays !== 15 && differentDays !== 30)) {
        setDateChip(0)
      }

      setFilters(prevState => ({
        ...prevState,
        startDate,
        endDate
      }));

    }
  }

  function selectTreeFilterAnswer(item, index) {
    if (treeFilter[index].selectedValue !== item.text) {
      let newTree = [...treeFilter]
      newTree[index].selectedValue = item.text
      newTree = newTree.slice(0, index + 1)
      setTreeFilter(newTree)
    }
  }

  function previousQuestion() {
    let newTree = [...treeFilter]
    newTree = newTree.slice(0, newTree.length - 1)
    newTree[newTree.length - 1].selectedValue = ''
    setTreeFilter(newTree)
    setNextQuestion(newTree[newTree.length - 1]._id)
  }

  return (
    <div className="dashboard">
      <aside>
        <div className='dashboard__title'>Dashboard</div>
        <div className='dashboard__sub__title'>
          <Sliders /> Filtros
        </div>
        <div className='dashboard__filter__separator' />
        <Accordion title="Por pergunta" headerClassName="dashboard__filter__header" mainClassName="dashboard__filter__body" >
          <Select options={_.orderBy(questions, 'question', 'asc')} value={questionFilter} setValue={setQuestionFilter} />
        </Accordion>
        <div className='dashboard__filter__separator' />
        <Accordion title="Por fluxo de pergunta" headerClassName="dashboard__filter__header" mainClassName="dashboard__filter__body" >
          {treeFilter.map((item, idx) => {
            return (
              <Select value={item} options={_.orderBy(item.options, 'text', 'asc')} title={item.question} setValue={selectTreeFilterAnswer} index={idx} />
            )
          })}
        </Accordion>
        <div className='dashboard__filter__separator' />
        <Accordion title="Por status" headerClassName="dashboard__filter__header" mainClassName="dashboard__filter__body" >
          <Chip selected={statusFilter.waiting} onClick={() => handleStatusFilterChange('waiting', !statusFilter.waiting)}>Aguardando</Chip>
          <Chip selected={statusFilter.pending} onClick={() => handleStatusFilterChange('pending', !statusFilter.pending)}>Pendente</Chip>
          <Chip selected={statusFilter.expired} onClick={() => handleStatusFilterChange('expired', !statusFilter.expired)}>Expirado</Chip>
          <Chip selected={statusFilter.refused} onClick={() => handleStatusFilterChange('refused', !statusFilter.refused)}>Recusado</Chip>
          <Chip selected={statusFilter.solved} onClick={() => handleStatusFilterChange('solved', !statusFilter.solved)}>Resolvidos</Chip>
          <Chip selected={statusFilter.unsolved} onClick={() => handleStatusFilterChange('unsolved', !statusFilter.unsolved)}>Não Resolvidos</Chip>
        </Accordion>
        <div className='dashboard__filter__separator' />
        <Accordion title="Por data" headerClassName="dashboard__filter__header" mainClassName="dashboard__filter__body" >
          <Chip selected={dateRange[0].dateChip === 30} onClick={() => setDateChip(30)}>Últimos 30 dias</Chip>
          <Chip selected={dateRange[0].dateChip === 15} onClick={() => setDateChip(15)}>Últimos 15 dias</Chip>
          <Chip selected={dateRange[0].dateChip === 7} onClick={() => setDateChip(7)}>Últimos 7 dias</Chip>
          <DateRange
            className='dashboard__date'
            editableDateInputs={true}
            onChange={item => handleDateChange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            locale={ptBR}
            rangeColors={['#009688']}
            showMonthAndYearPickers={true}
            showMonthArrow={false}
            scroll={{ enabled: true }}
            minDate={new Date("2021-08-01 ")}
            maxDate={new Date()}
          />
        </Accordion>
        <div className='dashboard__filter__separator' />
      </aside>
      <main>
        {loadingOptions ? <Spinner containerHeigth="100vh" /> : <>
          <div className="dashboard__card__title">
            {treeFilter.length > 1 && <ArrowLeft onClick={() => previousQuestion()} />}
            {questions.length > 0 && treeFilter[treeFilter.length - 1].question}
          </div>
          <div className="dashboard__card">
            {options.length > 0 ? options.map((e, i) =>
              <div key={i} className="dashboard__card__item">
                <div className="dashboard__card__item__text">
                  <Tooltip>{e.text}</Tooltip>
                </div>
                <div className="dashboard__card__item__bar">
                  <LinearProgress value={e.percentage} />
                </div>
                <div className="dashboard__card__item__percentage">
                  <Percentage value={e.percentage} decimal={1} decimalSeparator="," />
                  <small>{renderTotalAnswer(e.total)}</small>
                </div>
              </div>
            ) :
              'Não é possível coletar dados sobre essa pergunta.'}
          </div>
        </>}
      </main>
    </div>
  )
}

export default Dashboard
