import React, { useEffect, useState } from 'react'

function Percentage({ value = 0, maxValue = 100, decimal = 0, decimalSeparator = '.' }) {
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    let val = 0
    let maxVal = Number(Number((value / maxValue) * 100)).toFixed(decimal)
    if (maxVal < 0) maxVal = 0
    else if (maxVal > 100) maxVal = 100
    const interval = setInterval(() => {
      if (val <= maxVal) {
        setPercent(val.toFixed(decimal))
        val = val + (500 / maxValue)
      } else {
        setPercent(maxVal)
        clearInterval(interval)
      }
    }, 2);
  }, [value, maxValue, decimal])

  return (
    <div className="percentage">
      {String(percent).replace('.', decimalSeparator)}%
    </div>
  )
}

export default Percentage
