import { MessageCircle, Layers, Clock, ZapOff, Calendar, ThumbsUp, ThumbsDown } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import Spinner from '../../../../components/Spinner'

import './index.scss'

function Inbox() {
  const dispatch = useDispatch()
  const crm = useSelector(state => state.crm)
  const { page, inboxVisible, inbox, loadingInbox } = crm

  function setPage(page) {
    dispatch({ type: 'CHANGE_CRM_TICKETS', payload: [] })
    dispatch({ type: 'CHANGE_CRM_PAGE', payload: page })
    dispatch({ type: 'CHANGE_CRM_LOADING_TICKETS', payload: true })
    dispatch({ type: 'CHANGE_CRM_LOADING_CHAT', payload: true })
  }

  return (
    <div className={inboxVisible ? 'inbox inbox--opened' : 'inbox'}>
      <div className='inbox__title'>
        Inbox
      </div>
      {loadingInbox && <Spinner type={'green'} containerHeigth="90vh" />}
      {!loadingInbox &&
        <>
          <div onClick={() => page !== 'waiting' && setPage('waiting')} className={!inboxVisible ? 'inbox__page inbox__page--invisible' : page === 'waiting' ? 'inbox__page inbox__page--selected' : 'inbox__page'}>
            <Clock size={16} />
            Aguardando
            <span>{inbox.waiting}</span>
          </div>
          <div onClick={() => page !== 'open' && setPage('open')} className={!inboxVisible ? 'inbox__page inbox__page--invisible' : page === 'open' ? 'inbox__page inbox__page--selected' : 'inbox__page'}>
            <MessageCircle size={16} />
            Pendente
            <span>{inbox.open}</span>
          </div>
          <div className="inbox__separator"></div>
          <div onClick={() => page !== 'expired' && setPage('expired')} className={!inboxVisible ? 'inbox__page inbox__page--invisible' : page === 'expired' ? 'inbox__page inbox__page--selected' : 'inbox__page'}>
            <Calendar size={16} />
            Expirado
            <span>{inbox.expired}</span>
          </div>
          <div onClick={() => page !== 'refused' && setPage('refused')} className={!inboxVisible ? 'inbox__page inbox__page--invisible' : page === 'refused' ? 'inbox__page inbox__page--selected' : 'inbox__page'}>
            <ZapOff size={16} />
            Recusado
            <span>{inbox.refused}</span>
          </div>
          <div className="inbox__separator"></div>
          <div onClick={() => page !== 'solved' && setPage('solved')} className={!inboxVisible ? 'inbox__page inbox__page--invisible' : page === 'solved' ? 'inbox__page inbox__page--selected' : 'inbox__page'}>
            <ThumbsUp size={16} />
            Resolvidos
            <span>{inbox.solved}</span>
          </div>
          <div onClick={() => page !== 'unsolved' && setPage('unsolved')} className={!inboxVisible ? 'inbox__page inbox__page--invisible' : page === 'unsolved' ? 'inbox__page inbox__page--selected' : 'inbox__page'}>
            <ThumbsDown size={16} />
            Não Resolvido
            <span>{inbox.unsolved}</span>
          </div>

          <div className="inbox__separator"></div>
          <div onClick={() => page !== 'all' && setPage('all')} className={!inboxVisible ? 'inbox__page inbox__page--invisible' : page === 'all' ? 'inbox__page inbox__page--selected' : 'inbox__page'}>
            <Layers size={16} />
            Todos
            <span>{inbox.all}</span>
          </div>
        </>
      }
    </div>
    // </div>
  );
}

export default Inbox;