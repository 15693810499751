import { useEffect } from "react"
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import _ from 'lodash'
import axios from 'axios'
import './styles/index.scss'

import Snackbar from './components/Snackbar'

import Crm from "./containers/Crm"
import Dashboard from './containers/Dashboard'
import Login from "./containers/Login"
import NewPassword from './containers/NewPassword'
import Rating from './containers/Rating'
import Menu from "./components/Menu"

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    let user = localStorage.getItem('user')
    if (typeof (user) === 'string') {
      user = JSON.parse(user)
      axios.defaults.headers.common['authentication'] = user.token
      dispatch({ type: 'CHANGE_USER', payload: user })
    }
  }, [])

  const PrivateRoute = ({ children, ...props }) => {
    return (
      <Route {...props}>
        {_.isEmpty(localStorage.getItem('user')) ? <Redirect to="/login" /> : children}
      </Route>
    )
  }

  return (
    <Router>
      <div className='row'>
        <Menu />
        <div className='column'>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login">
              {!_.isEmpty(localStorage.getItem('user')) ? <Redirect to="/crm" /> : <Login />}
              {/* <Login /> */}
            </Route>
            <Route exact path={["/nova-senha", "/nova-senha/:email/:password"]}>
              <NewPassword />
            </Route>
            <Route exact path={["/avaliacao/:ticketId", "/avaliacao/:ticketId/:rating"]}>
              <Rating />
            </Route>
            <PrivateRoute exact path="/crm">
              <Crm />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path="*">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </div>
        <Snackbar />
      </div>
    </Router>
  );
}

export default App;
