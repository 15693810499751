import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { ReactComponent as CarretDownIcons } from '../../../../assets/icons/carretDown.svg'
import './index.scss'

function TicketSort({ defaultValue = "", options = [] }) {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState("")

  function handleClickOption(element) {
    setValue(element.title)
    setOpen(false)
    element.action()
  }

  return (
    <div className="ticket-sort">
      <div className={open ? "ticket-sort__field ticket-sort__field--open" : "ticket-sort__field"} onClick={() => setOpen(true)}>
        {value || defaultValue} <CarretDownIcons />
      </div>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <div className={open ? "ticket-sort__options ticket-sort__options--open" : "ticket-sort__options"}>
          {options.map((e, i) => (
            <span key={i} onClick={() => handleClickOption(e)}>{e.title}</span>
          ))}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default TicketSort
