export default function crm(state = {
  page: 'open',
  inboxVisible: true,
  inbox: {},
  tickets: [],
  selectedTicket: {},
  keyword: "",
  searchResults: {},
  chat: [],
  ticketsOrder: 'recent',
  ticketsPreventRequest: false,
  loadingInbox: true,
  loadingTicket: true,
  loadingChat: true,
  rate: 0,
  rateQty: 0,
}, action) {
  switch (action.type) {
    case 'CHANGE_CRM_PAGE':
      return { ...state, page: action.payload }
    case 'CHANGE_CRM_INBOX_VISIBLE':
      return { ...state, inboxVisible: action.payload }
    case 'CHANGE_CRM_SELECTED_TICKET':
      return { ...state, selectedTicket: action.payload }
    case 'CHANGE_CRM_TICKETS':
      return { ...state, tickets: action.payload }
    case 'CHANGE_CRM_INBOX':
      return { ...state, inbox: action.payload }
    case 'CHANGE_CRM_KEYWORD':
      return { ...state, keyword: action.payload }
    case 'CHANGE_CRM_SEARCH_RESULTS':
      return { ...state, searchResults: action.payload }
    case 'CHANGE_CRM_CHAT':
      return { ...state, chat: action.payload }
    case 'ADD_CRM_CHAT':
      return { ...state, chat: [...state.chat, ...action.payload] }
    case 'CHANGE_CRM_TICKETS_ORDER':
      return { ...state, ticketsOrder: action.payload }
    case 'CHANGE_TICKETS_PREVENT_REQUEST':
      return { ...state, ticketsPreventRequest: action.payload }
    case 'REMOVE_CRM_TICKET_FROM_LIST':
      let index
      state.tickets.map((item, count) => {
        if (item._id === state.selectedTicket._id) {
          index = count
        }
        return null
      })
      return {
        ...state, tickets: [
          ...state.tickets.slice(0, index),
          ...state.tickets.slice(index + 1)
        ]
      }

    case 'CHANGE_CRM_LOADING_INBOX':
      return { ...state, loadingInbox: action.payload }
    case 'CHANGE_CRM_LOADING_TICKETS':
      return { ...state, loadingTicket: action.payload }
    case 'CHANGE_CRM_LOADING_CHAT':
      return { ...state, loadingChat: action.payload }
    case 'CHANGE_LOADING_MESSAGE':
      return {
        ...state,
        chat: state.chat.map(message => {
          if (message._id === action.payload.messageId) {
            return {
              ...message,
              attachment: message.attachment.map(item => {
                if (item._id === action.payload.attachmentId) {
                  return { ...item, loading: action.payload.loading }
                }
                return { ...item }
              })
            }
          }
          return { ...message }
        })
      }
    case 'CHANGE_CRM_RATE':
      return { ...state, rate: action.payload }
    case 'CHANGE_CRM_RATE_QTY':
      return { ...state, rateQty: action.payload }
    default:
      return state
  }
}
