import { useSelector } from "react-redux";
import _ from 'lodash'

function useOrderTicketList() {
  const { ticketsOrder } = useSelector(state => state.crm)

  function sortList(list) {
    switch (ticketsOrder) {
      case 'recent':
        return _.orderBy(list, 'lastMessageDate', 'desc')
      case 'older':
        return _.orderBy(list, 'lastMessageDate', 'asc')
      case 'name':
        return _.orderBy(list, [list => list['contactName'].toLowerCase()], 'asc')
      default:
        break;
    }
  }
  return sortList
}

export { useOrderTicketList }