import React, { useEffect, useState } from 'react'
import './index.scss'

function LinearProgress({ value = 0, maxValue = 100 }) {
  const [progressLine, setProgressLine] = useState(0)

  useEffect(() => {
    const total = Number(Number((value / maxValue) * 100))
    if (total <= 0) return 0
    setProgressLine(total)
  }, [value, maxValue])

  return (
    <div className="linear-progress">
      <div className="linear-progress__container">
        <div className="linear-progress__bg">
          <div className={`linear-progress__line`} style={{ width: `${progressLine}%` }}></div>
        </div>
      </div>
    </div>
  )
}

export default LinearProgress
