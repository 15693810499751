import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function createCertificate(protocol, status, dateBegin, dateEnd, clientName, clientDocument, businessName, businessCNPJ, formData, chatData = []) {
  const documentConfig = {
    pageMargins: [30, 30, 30, 30]
  };
  const pdfStyles = {
    header: {
      fontSize: 20,
      margin: [0, 8, 0, 40],
      alignment: "center"
    },
    category: {
      fontSize: 18,
      alignment: "left",
      margin: [0, 45, 0, 8],
      marginLeft: 0
    },
    titleFirstRow: {
      bold: true,
      margin: [0, 14, 0, 0],
      fontSize: 18
    },
    textFirstRow: {
      margin: [0, 14, 0, 0],
      fontSize: 18
    },
    title: {
      bold: true,
      margin: [0, 8, 0, 8],
      fontSize: 16
    },
    text: {
      margin: [0, 0, 0, 8],
      fontSize: 16
    }
  };

  const pdfSeparator = () => ({
    canvas: [
      {
        type: "line",
        x1: 0,
        y1: 0,
        x2: 535,
        y2: 0,
        lineWidth: 1,
        lineColor: "#D9D9D9"
      }
    ]
  });

  const pdfFooter = (currentPage, pageCount) => ({
    margin: [30, 10],
    columns: [
      {
        text: status === 'solved' ? 'Certidão de Resolução' : `Certidão de Tentativa de Resolução`,
        fontSize: 10,
        alignment: "left"
        //background: "red"
      },
      {
        text: `${moment().format("DD/MM/YYYY")}`,
        fontSize: 10,
        alignment: "center"
        //background: "red"
      },
      {
        text: `Falejur ${currentPage.toString() + "/" + pageCount}`,
        fontSize: 10,
        alignment: "right"
        //background: "red"
      }
    ]
  });

  const dateFormat = (date) => moment(date).format("DD/MM/YYYY - HH:mm");

  const docDefinition = {
    ...documentConfig,
    content: [
      {
        svg: `<svg width="95" height="30" viewBox="0 0 95 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9818 3.75H3.74544V15C3.74544 21.2132 8.77611 26.25 14.9818 26.25V30C6.70756 30 0 23.2843 0 15V0H14.9818V3.75Z" fill="#009688"/>
          <path d="M14.9818 26.25H26.2181V15C26.2181 8.7868 21.1874 3.75 14.9818 3.75V7.5C19.1189 7.5 22.4726 10.8579 22.4726 15V22.5H14.9818V26.25Z" fill="#009688"/>
          <path d="M14.9818 7.5C10.8447 7.5 7.49088 10.8579 7.49088 15C7.49088 19.1421 10.8447 22.5 14.9818 22.5V18.75C12.9132 18.75 11.2363 17.0711 11.2363 15C11.2363 12.9289 12.9132 11.25 14.9818 11.25V7.5Z" fill="#009688"/>
          <path d="M14.9818 18.75V11.25C17.0503 11.25 18.7272 12.9289 18.7272 15C18.7272 17.0711 17.0503 18.75 14.9818 18.75Z" fill="#009688"/>
          <path d="M43.5394 10.7737H38.2089V13.9361H43.3871V16.4508H38.2089V21.5945H35.5817V8.25896H43.5394V10.7737Z" fill="#3B3B3B"/>
          <path d="M52.5737 12.0691H55.0296V21.5945H52.5737V20.4705C51.8376 21.3976 50.8032 21.8612 49.4706 21.8612C48.2014 21.8612 47.11 21.3786 46.1961 20.4133C45.295 19.4354 44.8445 18.2416 44.8445 16.8318C44.8445 15.422 45.295 14.2345 46.1961 13.2693C47.11 12.2914 48.2014 11.8024 49.4706 11.8024C50.8032 11.8024 51.8376 12.266 52.5737 13.1931V12.0691ZM48.0428 18.775C48.5378 19.2703 49.166 19.518 49.9275 19.518C50.689 19.518 51.3172 19.2703 51.8122 18.775C52.3199 18.267 52.5737 17.6192 52.5737 16.8318C52.5737 16.0444 52.3199 15.403 51.8122 14.9077C51.3172 14.3997 50.689 14.1456 49.9275 14.1456C49.166 14.1456 48.5378 14.3997 48.0428 14.9077C47.5478 15.403 47.3003 16.0444 47.3003 16.8318C47.3003 17.6192 47.5478 18.267 48.0428 18.775Z" fill="#3B3B3B"/>
          <path d="M57.2565 21.5945V7.68744H59.7124V21.5945H57.2565Z" fill="#3B3B3B"/>
          <path d="M64.0352 17.8415C64.3652 19.0353 65.26 19.6323 66.7195 19.6323C67.6587 19.6323 68.3694 19.3147 68.8517 18.6797L70.8316 19.8228C69.8924 21.1817 68.509 21.8612 66.6814 21.8612C65.1077 21.8612 63.8448 21.3849 62.893 20.4324C61.9411 19.4799 61.4652 18.2797 61.4652 16.8318C61.4652 15.3966 61.9347 14.2028 62.8739 13.2503C63.8131 12.285 65.0188 11.8024 66.4911 11.8024C67.8871 11.8024 69.0357 12.285 69.9369 13.2503C70.8507 14.2155 71.3076 15.4093 71.3076 16.8318C71.3076 17.1493 71.2758 17.4859 71.2124 17.8415H64.0352ZM63.9971 15.9364H68.8517C68.7121 15.2887 68.4202 14.8061 67.976 14.4886C67.5445 14.171 67.0495 14.0123 66.4911 14.0123C65.8311 14.0123 65.2854 14.1837 64.8538 14.5267C64.4223 14.8569 64.1368 15.3268 63.9971 15.9364Z" fill="#3B3B3B"/>
          <path d="M74.4901 10.9261C74.0713 10.9261 73.7096 10.78 73.405 10.4879C73.1131 10.1831 72.9671 9.82747 72.9671 9.42106C72.9671 9.01464 73.1131 8.65903 73.405 8.35421C73.7096 8.0494 74.0713 7.897 74.4901 7.897C74.8962 7.897 75.2453 8.0494 75.5372 8.35421C75.8418 8.65903 75.9941 9.01464 75.9941 9.42106C75.9941 9.82747 75.8418 10.1831 75.5372 10.4879C75.2453 10.78 74.8962 10.9261 74.4901 10.9261ZM73.2527 21.785V12.0691H75.7085V21.785C75.7085 23.182 75.3658 24.1981 74.6805 24.8331C74.0078 25.4808 72.9671 25.7602 71.5583 25.6713V23.3091C72.1421 23.3472 72.5673 23.2455 72.8338 23.0042C73.1131 22.7629 73.2527 22.3565 73.2527 21.785Z" fill="#3B3B3B"/>
          <path d="M84.2311 12.0691H86.6869V21.5945H84.2311V20.5276C83.6346 21.4167 82.689 21.8612 81.3945 21.8612C80.3538 21.8612 79.4907 21.5119 78.8054 20.8134C78.1327 20.1149 77.7964 19.1496 77.7964 17.9177V12.0691H80.2522V17.6129C80.2522 18.2479 80.4236 18.7369 80.7662 19.0798C81.1089 19.41 81.5658 19.5751 82.1369 19.5751C82.7715 19.5751 83.2792 19.3782 83.6599 18.9845C84.0407 18.5908 84.2311 18.0002 84.2311 17.2128V12.0691Z" fill="#3B3B3B"/>
          <path d="M91.392 13.7075C91.6205 13.0979 91.9949 12.6406 92.5152 12.3358C93.0483 12.031 93.6384 11.8786 94.2857 11.8786V14.6219C93.5369 14.533 92.8643 14.6854 92.2677 15.0791C91.6839 15.4728 91.392 16.1269 91.392 17.0414V21.5945H88.9362V12.0691H91.392V13.7075Z" fill="#3B3B3B"/>
          </svg>
          `,
        width: 95,
        alignment: "center"
      },
      {
        text: status === 'solved' ? 'Certidão de Resolução' : `Certidão de Tentativa de Resolução`,
        style: "header"
      },
      pdfSeparator(),
      {
        columns: [
          { width: "30%", text: `Protocolo`, style: "titleFirstRow" },
          { width: "auto", text: `${protocol}`, style: "textFirstRow" }
        ]
      },
      {
        columns: [
          { width: "30%", text: `Situação`, style: "titleFirstRow" },
          { width: "auto", text: `${status === 'solved' ? 'Resolvido' : 'Não resolvido'}`, style: "textFirstRow" }
        ]
      },
      {
        columns: [
          {
            width: "30%",
            text: `Inicio da Tratativa`,
            style: "titleFirstRow"
          },
          {
            width: "auto",
            text: `${dateFormat(dateBegin)}`,
            style: "textFirstRow"
          }
        ]
      },
      {
        columns: [
          { width: "30%", text: `Fim da Tratativa`, style: "titleFirstRow" },
          {
            width: "auto",
            text: `${dateFormat(dateEnd)}`,
            style: "textFirstRow"
          }
        ]
      },
      { text: "Dados do Cliente", style: "category" },
      pdfSeparator(),
      {
        columns: [
          { width: "30%", text: `Nome Completo`, style: "titleFirstRow" },
          { width: "auto", text: `${clientName}`, style: "textFirstRow" }
        ]
      },
      {
        columns: [
          { width: "30%", text: `CPF`, style: "titleFirstRow" },
          { width: "auto", text: `${clientDocument}`, style: "textFirstRow" }
        ]
      },
      { text: "Dados da Empresa", style: "category" },
      pdfSeparator(),
      {
        columns: [
          { width: "30%", text: `Nome`, style: "titleFirstRow" },
          { width: "auto", text: `${businessName}`, style: "textFirstRow" }
        ]
      },
      {
        columns: [
          { width: "30%", text: `CNPJ`, style: "titleFirstRow" },
          { width: "auto", text: `${businessCNPJ}`, style: "textFirstRow" }
        ]
      },
      { text: "Fomulário", style: "category" },
      pdfSeparator(),
      {
        text: `${clientName} (${dateFormat(formData.createdAt)})`,
        style: "title"
      },
      {
        text: `${formData.text.replaceAll("<br/>", '\n').replaceAll("<p>", '\n').replace(/<\/?[^>]+(>|$)/g, " ")}`,
        style: "text"
      },
      { text: "Chat", style: "category" },
      pdfSeparator(),
      ...chatData.map((e, i) => {
        if (['company', 'client'].includes(e.userProfile) && i > 0) {
          return ([
            {
              text: `${e.userProfile === 'client' ? clientName : businessName} (${dateFormat(e.createdAt)})`,
              style: "title"
            },
            {
              text: `${e.text.replace(/<\/?[^>]+(>|$)/g, " ").replaceAll('\n', "<br/>")}`,
              style: "text"
            }
          ])
        }
      })
    ],

    footer: pdfFooter,
    styles: pdfStyles
  };

  // pdfMake.createPdf(docDefinition).open();
  // pdfMake.createPdf(docDefinition).download(`Certificado ${protocol}.pdf`);
  let file = new Promise((resolve, reject) => {
    pdfMake.createPdf(docDefinition).getBlob(function (blob) {
      resolve(blob);
    })
  })
  return file
}

export default createCertificate;