import { useSelector, useDispatch } from 'react-redux'
import { getHour } from '../../../../constants/validate'
import moment from 'moment'
import _ from 'lodash'

import { useOrderTicketList } from '../../../../hooks/useOrderTicketList'

import Avatar from '../../../../components/Avatar'
import Spinner from '../../../../components/Spinner'
import { Chip, SearchBar } from '../../../../components/DesignSystem'

import TicketSort from '../TicketSort'

import { ReactComponent as MenuIcon } from '../../../../assets/icons/menu.svg'
import './index.scss'

function TicketList({ getMessages }) {
  const dispatch = useDispatch()
  const orderTicketsList = useOrderTicketList()
  const { inboxVisible, tickets, selectedTicket, searchResults, keyword, page, ticketsOrder, loadingTicket } = useSelector(state => state.crm)

  function handleClickTicket(element) {
    if (selectedTicket?.protocol !== element.protocol) {
      setSelectedTicket(element)
      getMessages(element.protocol)
      dispatch({ type: 'CHANGE_CRM_LOADING_CHAT', payload: true })
    }
  }

  function handleOnChangeSearch(value) {
    dispatch({ type: 'CHANGE_CRM_KEYWORD', payload: value })
    dispatch({ type: 'CHANGE_CRM_LOADING_TICKETS', payload: true })
  }

  function setInboxVisible(bool) {
    dispatch({ type: 'CHANGE_CRM_INBOX_VISIBLE', payload: bool })
  }

  function setSelectedTicket(protocol) {
    dispatch({ type: 'CHANGE_CRM_SELECTED_TICKET', payload: protocol })
  }

  function setTicketsOrder(order) {
    dispatch({ type: 'CHANGE_CRM_TICKETS_ORDER', payload: order })
  }

  function setSearchHighlight(string, value) {
    let newString = string
    let valueIndex = newString.indexOf(value)
    if (valueIndex > 10 && newString.length > 20) {
      newString = `...${newString.slice(valueIndex - 10)}`
    }
    return newString.replaceAll(value, `<strong>${value}</strong>`)
  }

  function returnSelectedPage() {
    switch (page) {
      case 'waiting':
        return 'Aguardando'
      case 'open':
        return 'Pendente'
      case 'solved':
        return 'Resolvidos'
      case 'unsolved':
        return 'Não Resolvidos'
      case 'expired':
        return 'Expirado'
      case 'refused':
        return 'Recusado'
      case 'all':
        return 'Todos'
      default:
        break;
    }
  }

  function setTicketDate(date) {
    let titleDate = new Date(date)
    let todayDate = new Date()
    titleDate = titleDate.setHours(0, 0, 0, 0)
    todayDate = todayDate.setHours(0, 0, 0, 0)
    let difference = moment(todayDate).diff(moment(titleDate), 'days')

    if (difference === 0) {
      return moment(date).local().format('HH:mm')
    }

    if (difference === 1) {
      return 'Ontem'
    }

    if (difference < 7) {
      switch (moment(date).day()) {
        case 0:
          return 'Domingo'
        case 1:
          return 'Segunda-Feira'
        case 2:
          return 'Terça-Feira'
        case 3:
          return 'Quarta-Feira'
        case 4:
          return 'Quinta-Feira'
        case 5:
          return 'Sexta-Feira'
        case 6:
          return 'Sábado'
        default:
          break;
      }
    }

    return moment(date).local().format('DD/MM/YYYY')
  }

  function renderNotFoundMessage() {
    let defaultNotFound = false,
      searchNotFount = false

    if (tickets.length === 0) defaultNotFound = true
    if (!_.isEmpty(searchResults) && ((searchResults.name.length === 0) && (searchResults.messages.length === 0) && (searchResults.protocol.length === 0))) searchNotFount = true

    if (!loadingTicket && (defaultNotFound || searchNotFount)) {
      return <div className='ticket-list__not-found'>
        {/* Uncomment and be happy 😅 */}
        {/* <img src="https://media.tenor.com/images/6bfb6415d4711519d1c322a4b409c30e/tenor.gif" /> */}
        Nenhum ticket encontrado
      </div>
    }
  }

  return (
    <section className="ticket-list">
      <header>
        <div className="row">
          <h1><MenuIcon onClick={() => setInboxVisible(!inboxVisible)} />{returnSelectedPage()}</h1>
          <TicketSort
            defaultValue="Recentes"
            options={[
              { title: "A-Z", action: () => setTicketsOrder('name') },
              { title: "Mais Antigos", action: () => setTicketsOrder('older') },
              { title: "Recentes", action: () => setTicketsOrder('recent') },
            ]}
          />
        </div>
        <div className="row" style={{ marginTop: 16 }}>
          <SearchBar size="small" onChange={handleOnChangeSearch} />
        </div>
        {/* <div className="row ticket-list__priority">
          <h3>Prioridade</h3>
          <ul>
            <li><Chip>Alta</Chip></li>
            <li><Chip>Média</Chip></li>
            <li><Chip>Baixa</Chip></li>
          </ul>
        </div> */}
      </header>
      <main>
        {loadingTicket && <Spinner type={'green'} containerHeigth="67vh" />}

        {renderNotFoundMessage()}

        {(!loadingTicket && !keyword) && orderTicketsList(tickets).map((e, i) => (
          <section key={i} className={((!_.isEmpty(selectedTicket)) && (selectedTicket.protocol === e.protocol)) ? "ticket-list__item ticket-list__item--selected" : "ticket-list__item"} onClick={() => handleClickTicket(e)}>
            <div className="ticket-list__item__left">
              <Avatar name={e.contactName} size="medium" />
            </div>
            <div className="ticket-list__item__center">
              <h1>{e.contactName}</h1>
              <span>{e.protocol}</span>
              {!["solved", "unsolved"].includes(page) && <p>{e.lastMessage.replace(/<\/?[^>]+(>|$)/g, "")}</p>}
            </div>
            <div className="ticket-list__item__right">
              <span>{e.lastMessageDate && setTicketDate(e.lastMessageDate)}</span>
              {e.newMessages > 0 && <span className="ticket-list__item__badge">{e.newMessages}</span>}
            </div>
          </section>
        ))}

        {(!loadingTicket && keyword) && searchResults.name.length > 0 &&
          <section className="ticket-list__search">
            <div className="ticket-list__search__section-title">
              Nome
            </div>
            {orderTicketsList(searchResults.name).map((e, i) => (
              <div key={i} className="ticket-list__search__item" onClick={() => handleClickTicket(e)}>
                <div className="ticket-list__search__item__left">
                  <Avatar name={e.contactName} size="medium" />
                </div>
                <div className="ticket-list__search__item__right">
                  <h1 dangerouslySetInnerHTML={{ __html: setSearchHighlight(e.contactName.replace(/<\/?[^>]+(>|$)/g, " "), keyword) }} />
                  <p>{e.protocol}</p>
                </div>
              </div>
            ))}
          </section>
        }
        {(!loadingTicket && keyword) && searchResults.messages.length > 0 &&
          <section className="ticket-list__search">
            <div className="ticket-list__search__section-title">
              Mensagens
            </div>
            {orderTicketsList(searchResults.messages).map((e, i) => (
              <div key={i} className="ticket-list__search__item" onClick={() => handleClickTicket(e)}>
                <div className="ticket-list__search__item__left">
                  <Avatar name={e.contactName} size="medium" />
                </div>
                <div className="ticket-list__search__item__right">
                  <h1>{e.contactName}</h1>
                  <p dangerouslySetInnerHTML={{ __html: setSearchHighlight(e.lastMessage.replace(/<\/?[^>]+(>|$)/g, " "), keyword) }} />
                </div>
              </div>
            ))}
          </section>
        }
        {(!loadingTicket && keyword) && searchResults.protocol.length > 0 &&
          <section className="ticket-list__search">
            <div className="ticket-list__search__section-title">
              Protocolos
            </div>
            {orderTicketsList(searchResults.protocol).map((e, i) => (
              <div key={i} className="ticket-list__search__item" onClick={() => handleClickTicket(e)}>
                <div className="ticket-list__search__item__left">
                  <Avatar name={e.contactName} size="medium" />
                </div>
                <div className="ticket-list__search__item__right">
                  <h1>{e.contactName}</h1>
                  <p dangerouslySetInnerHTML={{ __html: setSearchHighlight(e.protocol.replace(/<\/?[^>]+(>|$)/g, " "), keyword) }} />
                </div>
              </div>
            ))}
          </section>
        }
      </main>
    </section >
  )
}

export default TicketList
