import { useEffect, useState } from 'react';
import { Inbox, Layout } from 'react-feather'
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, } from 'react-redux';

import UserContextMenu from '../../containers/Crm/components/UserContextMenu'

// import { ReactComponent as Logo } from '../../assets/falejur.svg'
import './index.scss'

function Menu() {
  const history = useHistory()
  const location = useLocation();
  const { user: { token } } = useSelector(state => state.app)
  const [page, setPage] = useState("")
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    setPage(location.pathname.replace('/', ''))
  }, [location])

  useEffect(() => {
    if (token && !location.pathname.includes('/avaliacao')) {
      setShowMenu(true)
    }
  }, [token])

  function handleClickPage(page) {
    history.push(page)
  }

  return (
    showMenu ?
      <div className='menu'>
        <div className="menu__logo">
          Falejur
        </div>
        <div onClick={() => handleClickPage('crm')} className={page === 'crm' ? 'menu__page menu__page--active' : 'menu__page'}>
          <Inbox size={36} />
        </div>
        <div onClick={() => handleClickPage('dashboard')} className={page === 'dashboard' ? 'menu__page menu__page--active' : 'menu__page'}>
          <Layout size={36} />
        </div>
        <div className={`menu__page__selector menu__page__selector--${page}`} />
        <div className='menu__profile'>
          <div className='menu__profile__photo'>
            <UserContextMenu />
          </div>
          <div className='menu__profile__notification' />
        </div>
      </div>
      : <></>
  );
}

export default Menu;