import React from 'react'
import * as FeatherIcon from 'react-feather'
import Loading from '../../Loading'
import './index.scss'

/**
 * 
 * @param {String} size - small, normal, large
 * @param {Boolean} rounded
 * @param {Boolean} contained
 * @param {Boolean} outlined
 * @param {Boolean} disabled
 * @param {String} icon - Feather icon name
 * @param {Boolean} loading
 * 
 * @returns 
 */
function Button({ children = "", icon = "", disabled = false, rounded = false, contained = false, outlined = false, color = "green", size = "normal", loading = false, ...props }) {

  function setClassName() {
    let className = "btn"
    if (rounded) className += " btn--rounded"
    if (contained) className += ` btn--contained--${color}`
    if (outlined) className += ` btn--outlined--${color}`
    if (size) className += ` btn--${size}`
    if (loading) className += ` btn--loading`
    return className
  }

  const CustomIcon = () => {
    const Icon = FeatherIcon[icon]
    return <Icon />
  }

  return (
    <button className={setClassName()} disabled={disabled} {...props}>
      {icon && <CustomIcon />}
      {children}
      {loading && <Loading />}
    </button>
  )
}

export default Button
