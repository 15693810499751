import React, { useState, useRef, useEffect } from "react"
import { ChevronDown } from "react-feather"
import "./style.scss"

function Accordion({ title, subtitle, headerClassName = "", mainClassName = "", icon, ...props }) {
  const [isOpen, setIsOpen] = useState(false)
  const [maxHeight, setMaxHeight] = useState("0px")
  const [iconClassName, setIconClassName] = useState("accordion__header__icon")
  const [overflow, setOverflow] = useState('hidden')
  const content = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(`${content.current.scrollHeight}px`)
    }
  }, [props.children])

  useEffect(() => {
    let timeout = null

    if (isOpen)
      timeout = setTimeout(() => setOverflow('visible'), 600)
    else
      setOverflow('hidden')

    return () => clearTimeout(timeout)
  }, [isOpen])

  function toggleAccordion() {
    setIsOpen(!isOpen)
    setMaxHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`)
    setIconClassName(isOpen ? "accordion__header__icon" : "accordion__header__icon rotate")
  }

  return (
    <section className="accordion">
      <header className={`accordion__header ${headerClassName}`} onClick={toggleAccordion}>
        <div><h1>{title}</h1>{subtitle && <small>{subtitle}</small>}</div>
        <div className={iconClassName}>
          {icon || <ChevronDown />}
        </div>
      </header>
      <main
        ref={content}
        style={{ maxHeight: `${maxHeight}`, overflow: overflow }}
      >
        <div className={`accordion__content ${mainClassName}`}>
          {props.children}
        </div>
      </main>
    </section>
  );
}

export default Accordion;
