import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'

import { Button } from '../DesignSystem'

import './index.scss'

function Snackbar() {
  const dispatch = useDispatch()
  const { snackbar, undo } = useSelector(state => state.app)
  const [message, setMessage] = useState("")

  useEffect(() => {
    if (!_.isEmpty(snackbar)) {
      setMessage(snackbar)
      setTimeout(() => {
        dispatch({ type: 'CHANGE_SNACKBAR', payload: "" })
      }, 4000)
    }
  }, [snackbar])

  function handleClickClose() {
    dispatch({ type: 'CHANGE_SNACKBAR', payload: "" })
  }

  function handleClickUndo() {
    undoAction(undo)
  }

  async function undoAction(undo) {
    try {
      switch (undo.data.method) {
        case "GET":
          await axios.get(undo.data.path, undo.data.params)
        case "POST":
          await axios.post(undo.data.path, undo.data.body)
        default:
          break
      }
      dispatch({ type: 'CHANGE_CRM_UNDO', payload: { action: "", data: {} } })
      dispatch({ type: 'CHANGE_SNACKBAR', payload: "" })
      return
    } catch (error) {
      console.log(error)
      dispatch({ type: 'CHANGE_SNACKBAR', payload: "Ocorreu um erro ao desfazer esta ação" })
    }
  }

  function renderActions() {
    return (
      <div className='snackbar__actions'>
        <Button size="small" onClick={handleClickUndo}>Desfazer</Button>
        <Button size="small" onClick={handleClickClose} icon="X" />
      </div>
    )
  }

  return (
    <div className={!_.isEmpty(snackbar) ? "snackbar snackbar--visible" : "snackbar"}>
      <span>{message}</span>
      {undo && renderActions()}
    </div>
  )
}

export default Snackbar
