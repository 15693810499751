import React from 'react'
import './index.scss'

function Chip({ children = "", selected = false, disabled = false, selectedColor = "green", ...props }) {

  function setClassName() {
    let className = "chip"
    if (selected) className += ` chip--selected chip--selected--${selectedColor}`
    if (disabled) className += " chip--disabled"
    return className
  }

  return (
    <div className={setClassName()} disabled={disabled} {...props}>
      {children}
    </div>
  )
}

export default Chip
