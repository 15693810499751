import { combineReducers } from 'redux'

import appReducer from './app'
import crmReducer from './crm'

const reducers = combineReducers({
  app: appReducer,
  crm: crmReducer,
})

export default reducers