import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { Star } from 'react-feather'
import axios from 'axios'
import _ from 'lodash'

import Button from '../../components/Button'
import Spinner from '../../components/Spinner'

import './index.scss'

function Rating() {
  const { ticketId/*, rating*/ } = useParams()
  const [loading, setLoading] = useState(true)
  const [showThanks, setShowThanks] = useState(false)
  const [showRating, setShowRating] = useState(false)
  const [showTicketRated, setShowTicketRated] = useState(false)
  const [contactName, setContactName] = useState("")
  const [rating, setRating] = useState(0)
  const [groupInfo, setGroupInfo] = useState({})

  useEffect(() => {
    if (!_.isEmpty(ticketId)) {
      setRate()
    }
  }, [ticketId])

  function handleClickSend() {
    if (rating > 0) {
      setRate(String(rating))
    }
  }

  function setRate(rate) {
    const params = {}
    params.ticketId = ticketId
    if (!_.isEmpty(rate)) params.rate = Number(rate)

    axios.get('tickets/rate/', { params })
      .then(response => {
        setLoading(false)
        setContactName(response.data.contactName)
        setShowThanks(true)
        setShowRating(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        if (error.response.status === 400) {
          setShowRating(true)
          setGroupInfo({ ...error.response.data.group, logo: error.response.data.logo })
        }
        if (error.response.status === 409) {
          setShowTicketRated(true)
          setGroupInfo({ ...error.response.data.group, logo: error.response.data.logo })
        }
      })
  }

  return (
    <div className="rating">
      {loading && <Spinner containerHeigth='100vh' />}
      {!loading && <header><img src={groupInfo.logo} alt={groupInfo.companyName} /></header>}

      {showThanks &&
        <main>
          <h1>Obrigado, {contactName}</h1>
          <p>Agradecemos a sua avaliação. Ela será usada para melhorar cada vez mais o nosso atendimento.</p>
        </main>
      }
      {showTicketRated &&
        <main>
          <h1>Obrigado</h1>
          <p>Você já efetuou uma avaliação anteriormente.</p>
        </main>
      }
      {showRating &&
        <main>
          <div>
            <h1>Avalie o seu atendimento</h1>
            <p>Como você classificaria sua experiência em nosso atendimento?</p>
          </div>
          <div className="rating__rate">
            {Array(5).fill(0).map((_, i) => (
              <div key={i} className={i + 1 <= rating ? `rating__rate--${i + 1} rating__rate--selected` : `rating__rate--${i + 1}`} >
                <Star onClick={() => setRating(i + 1)} />
              </div>
            ))}
          </div>
          <Button backgroundColor={groupInfo.rgb} height='48px' maxWidth='228px' color={groupInfo.fontColor} onClick={() => handleClickSend()}>Enviar meu feedback</Button>
        </main>
      }

      {!loading && <footer>Powered by <span>Sem Processo</span></footer>}
    </div >
  )
}

export default Rating
