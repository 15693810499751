import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useOrderTicketList } from '../../hooks/useOrderTicketList'
import axios from 'axios';
import _ from 'lodash';

import Chat from './components/Chat'
import Inbox from './components/Inbox'
import Menu from '../../components/Menu'
import TicketList from './components/TicketList'

import './index.scss'

const enableSync = true

function Crm() {
  const dispatch = useDispatch()
  const orderTicketList = useOrderTicketList()
  const { user } = useSelector(state => state.app)
  const { keyword, page, selectedTicket, chat } = useSelector(state => state.crm)

  useEffect(() => {
    setResizeScreen()
    window.addEventListener("resize", setResizeScreen)
    return () => window.removeEventListener("resize", setResizeScreen)
  }, [])

  useEffect(() => {

    axios.get('groups/get-group-rate')
      .then(response => {
        dispatch({ type: 'CHANGE_CRM_RATE', payload: response.data.rate })
        dispatch({ type: 'CHANGE_CRM_RATE_QTY', payload: response.data.rateQty })
      }).catch(e => {
        console.log(e)
      })

    let source = axios.CancelToken.source()

    const request = (source, selectFirstTicket) => {
      if (!_.isEmpty(user)) {
        getInbox()
        getTickets(page, keyword, source, selectFirstTicket)
      }
    }
    request(source, true)

    let synchronize = setInterval(() => enableSync && request(source, false), 6000)

    return () => {
      clearInterval(synchronize)
      source.cancel()
    }
  }, [user, page, keyword])

  useEffect(() => {
    let source = axios.CancelToken.source()

    const request = (source) => {
      if (!_.isEmpty(chat) && !_.isEmpty(selectedTicket) && (selectedTicket.status === 'open' || selectedTicket.status === 'waiting')) {
        getMessagesFromLastSequential(selectedTicket.protocol, chat[chat.length - 1].sequential, source)
      }
    }

    let synchronize = setInterval(() => enableSync && request(source), 3000)

    return () => {
      clearInterval(synchronize)
      source.cancel()
    }
  }, [chat, selectedTicket])

  function getTickets(type = "", keyword = "", source, selectFirstTicket = false) {
    axios.post('/tickets/get-all', { filter: { type, keyword } }, { cancelToken: source?.token })
      .then(response => {
        if (Array.isArray(response.data)) {
          dispatch({ type: 'CHANGE_CRM_TICKETS', payload: response.data })
          dispatch({ type: 'CHANGE_CRM_LOADING_TICKETS', payload: false })
          dispatch({ type: 'CHANGE_CRM_SEARCH_RESULTS', payload: {} })
          if (selectFirstTicket) {
            const orderedList = orderTicketList(response.data)
            dispatch({ type: 'CHANGE_CRM_SELECTED_TICKET', payload: orderedList[0] })
            getMessages(orderedList[0].protocol)
          }
        }
        if (!Array.isArray(response.data)) {
          dispatch({ type: 'CHANGE_CRM_SEARCH_RESULTS', payload: response.data })
          dispatch({ type: 'CHANGE_CRM_LOADING_TICKETS', payload: false })
        }
      }).catch(e => {
        console.log(e)
      })
  }

  function getInbox() {
    axios.get('/tickets/get-inbox')
      .then(response => {
        dispatch({ type: 'CHANGE_CRM_INBOX', payload: response.data })
        dispatch({ type: 'CHANGE_CRM_LOADING_INBOX', payload: false })
      })
  }

  function getMessages(protocol) {
    // dispatch({ type: 'CHANGE_CRM_CHAT', payload: [] })
    axios.get(`/messages/get-messages`, { params: { protocol } })
      .then(response => {
        dispatch({ type: 'CHANGE_CRM_CHAT', payload: response.data })
        dispatch({ type: 'CHANGE_CRM_LOADING_CHAT', payload: false })
      }).catch(e => {
        console.log(e)
      })
  }

  function getMessagesFromLastSequential(protocol, lastSequential, source) {
    axios.get(`/messages/get-last-sequential`, { params: { protocol, lastSequential } }, { cancelToken: source?.token })
      .then(response => {
        dispatch({ type: 'ADD_CRM_CHAT', payload: response.data })
      }).catch(e => {
        console.log(e)
      })
  }

  function setResizeScreen() {
    if (window.matchMedia("(min-width: 1140px)").matches) {
      dispatch({ type: 'CHANGE_CRM_INBOX_VISIBLE', payload: true })
    } else {
      dispatch({ type: 'CHANGE_CRM_INBOX_VISIBLE', payload: false })
    }
  }

  return (
    <div className='crm'>
      <Inbox />
      <TicketList getMessages={getMessages} />
      <Chat getMessages={getMessages} />
    </div>
  );
}

export default Crm;