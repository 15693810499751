import React, { useState, useEffect } from 'react'
import { ChevronDown } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'
import _, { set } from 'lodash'
import './index.scss'

function Select({
  index = null,
  options,
  placeholder,
  title,
  value,
  setValue
}) {
  const [optionsOpen, setOptionsOpen] = useState(false)
  return (
    <div className="select">
      {title && <div className='select__title'>{title}</div>}
      {options.length > 1 &&
        <>
          <div className='select__question' onClick={() => setOptionsOpen(!optionsOpen)} style={{ pointerEvents: optionsOpen ? 'none' : 'all' }}>
            {index !== null ? (value?.selectedValue ? <span>{value.selectedValue}</span> : 'Selecione') : value?.question ? <span>{value.question}</span> : 'Selecione'}
            <ChevronDown />
          </div>
          <OutsideClickHandler onOutsideClick={() => setOptionsOpen(false)}>
            <div className={optionsOpen ? 'select__options select__options--open' : 'select__options'}>
              {options.map(item => {
                if (item.text) {
                  if (item.nextQuestionId) {
                    return (
                      <span value={item._id} onClick={() => { setValue(item, index); setOptionsOpen(false) }}>{item.text}</span>
                    )
                  }
                }
                else {
                  return (
                    <span value={item._id} onClick={() => { setValue(item); setOptionsOpen(false) }}>{item.question}</span>
                  )
                }
              })}
            </div>
          </OutsideClickHandler >
        </>}
    </div >
  )
}

export default Select
