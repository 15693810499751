import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import OutsideClickHandler from 'react-outside-click-handler'
import { Star } from 'react-feather'

import './index.scss'

/**
 * FloatingMenu 
 * @param {string} position "top" or "bottom"
 * @param {string} align "left" or "center" or "right"
 * @param {array} options Array of objects with the example structure. If type is "divider" shows a divider
 * @param {string} classNameButtonContainer Class name for the button container
 * @param {string} classNameOption Class name for the option.
 * @returns {React.Component}
 * &nbsp;
 * ---  
 * @example 
 * ```js
 * { label: "Sample", icon: <Star />, action: () => alert('Sample'), disabled: false, type: "" }
 * ```
 */
function FloatingMenu({
  children,
  position = "top",
  align = "right",
  classNameButtonContainer = "",
  classNameOptions = "",
  options = [{ label: "Sample", icon: <Star />, action: () => alert('Sample'), disabled: false, type: "" }],
  ...props }) {

  const floatingMenuRef = useRef(null)
  const floatingMenuContainerRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [floatMenuPosition, setFloatingMenuPosition] = useState([null, null])
  const [floatMenuAnchor, setFloatingMenuAnchor] = useState([null, null])

  useEffect(() => setPosition(), [])
  useLayoutEffect(() => window.addEventListener('resize', setPosition), [])

  function handleOnClickItem(action) {
    if (action) {
      action()
      setTimeout(() => setIsOpen(false), 100)
    }
  }

  function setPosition() {
    if (!floatingMenuContainerRef.current) return
    const { x, y, width, height } = floatingMenuContainerRef.current.getBoundingClientRect()
    const positions = {
      top: {
        left: [x, y],
        center: [x + width / 2, y],
        right: [x + width, y],
      },
      bottom: {
        left: [x, y + height],
        center: [x + width / 2, y + height],
        right: [x + width, y + height]
      }
    }

    let newPosition = [position, align]

    const { width: floatWidth, height: floatHeight } = floatingMenuRef.current.getBoundingClientRect()
    const { width: screenWidth, height: screenHeight } = document.body.getBoundingClientRect()

    if (x + floatWidth > screenWidth) newPosition = [newPosition[0], "left"]
    else newPosition = [newPosition[0], "right"]
    if (y + floatHeight < screenHeight) newPosition = ["bottom", newPosition[1]]
    else newPosition = ["top", newPosition[1]]

    setFloatingMenuAnchor(newPosition)
    setFloatingMenuPosition(positions[newPosition[0]][newPosition[1]])
    // setFloatingMenuPosition(positions[position][align])
  }

  function renderLabel(label, icon) {
    if (icon) return <>{icon} {label}</>
    else return label
  }

  function renderOption(option, index) {
    switch (option.type) {
      case "divider":
        return <li key={index} className="floating-menu__divider"><hr /></li>
      case "header":
        return <li key={index} className="floating-menu__header">{option.label}</li>
      case "subOptions":
        return <li key={index} className="floating-menu__option floating-menu__option--list" onClick={() => setIsOpen(true)}>
          {renderLabel(option.label, option.icon)}<div className="floating-menu__option--list--icon" />
          <ul className={`floating-menu__sub-list floating-menu__sub-list--${floatMenuAnchor[0]}--${floatMenuAnchor[1]}`}>
            {option.options.map((option, index) => renderOption(option, index))}
          </ul>
        </li>
      default:
        return <li key={index} className="floating-menu__option" onClick={() => handleOnClickItem(option.action)}>
          {renderLabel(option.label, option.icon)}
        </li>
    }
  }


  function returnClassName() {
    if (isOpen) return `${classNameOptions} floating-menu__card floating-menu__card--${floatMenuAnchor[0]}--${floatMenuAnchor[1]} floating-menu__card--open`
    return `${classNameOptions} floating-menu__card floating-menu__card--${floatMenuAnchor[0]}--${floatMenuAnchor[1]} floating-menu__card--close`
  }

  function returnStyle() {
    return ({
      left: floatMenuPosition[0],
      top: floatMenuPosition[1],
    })
  }

  return (
    <div
      ref={floatingMenuContainerRef}
      className={`${classNameButtonContainer} floating-menu__container`}
      onClick={() => setIsOpen(isOpen ? false : true)}
      onMouseOver={setPosition}
    >
      {children}
      <div ref={floatingMenuRef} className={returnClassName()} style={returnStyle()}>
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <ul>
            {options.map((option, index) => renderOption(option, index))}
          </ul>
        </OutsideClickHandler>
      </div>
    </div >
  );
}


export default FloatingMenu