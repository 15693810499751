import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { LogOut, Star, DownloadCloud } from 'react-feather'
import download from 'downloadjs'
import { ClipLoader } from 'react-spinners'
import { useSelector } from 'react-redux'
import axios from 'axios'

import Avatar from '../../../../components/Avatar'
import Loading from '../../../../components/Loading'
import { ReactComponent as CarretDownIcons } from '../../../../assets/icons/carretDown.svg'
import './index.scss'

function UserContextMenu() {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(false)

  const { user: { name, companyName, groupId, departmentName } } = useSelector(state => state.app)
  const { rate, rateQty } = useSelector(state => state.crm)

  function handleClickLogout() {
    axios.delete('users/logout')
      .then(response => {
        localStorage.removeItem('user')
        window.location.href = "/login"
      }).catch(e => {
        console.log(e)
      })
  }

  function handleClickOption(element) {
    setValue(element.title)
    setOpen(false)
    element.action()
  }

  const base64 = (bytes, type) => {
    const byteCharacters = atob(bytes);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: type })
    return file
  }

  function handleDownloadReport() {
    setLoading(true)
    axios.post('tickets/generate-excel', { groupId })
      .then(response => {
        download(base64(response.data), `Relatório ${companyName}.xlsx`, 'application/x-www-form-urlencoded')
        setLoading(false)
      }).catch(e => {
        console.log(e)
        setLoading(false)
      })
  }

  return (
    <div className="user-context-menu">
      <div className={open ? "user-context-menu__field user-context-menu__field--open" : "user-context-menu__field"} onClick={() => setOpen(true)}>
        <Avatar size='xlarge' name={name} />
      </div>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <div className={open ? "user-context-menu__options user-context-menu__options--open" : "user-context-menu__options"}>
          <div className='user-context-menu__user'>
            {name}
            <span>{departmentName}</span>
          </div>
          <div className='user-context-menu__company'>
            {companyName}
            <span className='user-context-menu__options__item'>
              <Star />{rate}<p>({rateQty} avaliações)</p>
            </span>
          </div>
          <span className='user-context-menu__options__item' onClick={!loading && handleDownloadReport}>
            {loading ?
              <ClipLoader size={12} />
              :
              <DownloadCloud />
            }
            Baixar Relatório
          </span>
          <span className='user-context-menu__options__item' onClick={handleClickLogout}>
            <LogOut /> Sair
          </span>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default UserContextMenu
