import React, { useEffect, useState } from 'react'
import { Search, X } from 'react-feather'
import _ from 'lodash'

import { useDebounce } from '../../../hooks/useDebounce'

import './index.scss'

function SearchBar({ children = "", size = "medium", disabled = false, onChange = () => { }, ...props }) {
  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    onChange(debouncedSearch)
  }, [debouncedSearch])

  function handleClickClear() {
    setSearch("")
  }

  return (
    <div className={!disabled ? `search-bar search-bar--${size}` : `search-bar search-bar--${size} search-bar--disabled`}>
      <Search className="search-bar__icon-left" />
      <input type="text" inputMode="search" placeholder="Busca" onChange={evt => setSearch(evt.target.value)} value={search} />
      <X className={!_.isEmpty(search) ? "search-bar__icon-right search-bar__icon-right--visible" : "search-bar__icon-right"} onClick={handleClickClear} />
    </div>
  )
}

export default SearchBar
